import { template as template_444da7d1ac7848fcbfa02b4b929ca3fc } from "@ember/template-compiler";
const FKLabel = template_444da7d1ac7848fcbfa02b4b929ca3fc(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;

import { template as template_da824bbf37604b3491c1400150bb7c86 } from "@ember/template-compiler";
const FKText = template_da824bbf37604b3491c1400150bb7c86(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;

import { template as template_da4b403414fd43058e75c6abc1d5499e } from "@ember/template-compiler";
const SidebarSectionMessage = template_da4b403414fd43058e75c6abc1d5499e(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;

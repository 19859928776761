import { template as template_25c790daeca24e938d3225395c8860c4 } from "@ember/template-compiler";
const FKControlMenuContainer = template_25c790daeca24e938d3225395c8860c4(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
